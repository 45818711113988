@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: 'montstrat'; 
    src: url('../../cricketposters/public/fonts/montstrat/Montserrat-Bold.ttf') format('truetype'); 
    font-weight: bold; 
    font-style: normal;
}
@font-face {
    font-family: 'montstratitalic'; 
    src: url('../../cricketposters/public/fonts/montstrat/Montserrat-ExtraBoldItalic.ttf') format('truetype'); 
    font-weight: bold; 
    font-style: normal;
}
@font-face {
    font-family: 'baumanregular'; 
    src: url('../../cricketposters/public/fonts/montstrat/Baumans-Regular.ttf') format('truetype'); 
    font-weight: bold; 
    font-style: normal;
}
@font-face {
    font-family: 'battlest'; 
    src: url('../../cricketposters/public/fonts/montstrat/BATTLEST.TTF') format('truetype'); 
    font-weight: bold; 
    font-style: normal;
}
@font-face {
    font-family: 'advertising'; 
    src: url('../../cricketposters/public/fonts/montstrat/advertising-script.bold.ttf') format('truetype'); 
    font-weight: bold; 
    font-style: normal;
}
@font-face {
    font-family: 'RosellindaAlyamore'; 
    src: url('../../cricketposters/public/fonts/montstrat/Rosellinda Alyamore.otf') format('truetype'); 
    font-weight: bold; 
    font-style: normal;
}
@font-face {
    font-family: 'BebasNeue'; 
    src: url('../../cricketposters/public/fonts/montstrat/BebasNeue-Regular.ttf') format('truetype'); 
    font-weight: bold; 
    font-style: normal;
}
@font-face {
    font-family: 'Kelsans'; 
    src: url('../../cricketposters/public/fonts/montstrat/KelsonSans-Regular.ttf') format('truetype'); 
    font-weight: bold; 
    font-style: normal;
}
@font-face {
    font-family: 'myraidpro'; 
    src: url('../../cricketposters/public/fonts/montstrat/MYRIADPRO-REGULAR.woff') format('truetype'); 
    font-weight: bold; 
    font-style: normal;
}
@font-face {
    font-family: 'Audiowide'; 
    src: url('../../cricketposters/public/fonts/montstrat/Audiowide-Regular.ttf') format('truetype'); 
    font-weight: bold; 
    font-style: normal;
}
